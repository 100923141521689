import React, { useEffect, useRef, useState } from "react";
import { Table, Tooltip, Col, Form, Input } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";

function Subscription() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const users_list = useSelector((state) =>
    state?.allapi?.category_list ? state?.allapi?.category_list : {}
  );

  console.log("this is a user list", users_list);

  const listdata = Array.isArray(users_list?.data)
    ? users_list.data
    : Array.isArray(users_list)
    ? users_list
    : [];
  console.log("user list for subscription", listdata);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [showDelelt, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const [cateID, setcateID] = useState("");

  const hendleDelete = (e) => {
    console.log(e);
    // deleteProduct
    setShowDelete(false);
    dispatch(allapiAction.delete_subscription({ id: e, page: page }));
    dispatch(allapiAction.get_subscription_plan(page));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const hendleModalShow = (e) => {
    // setShowDelete(true)
    setcateID(e);
    setIsModalOpen(true);
  };

  const [cateDetail, setcateDetail] = useState({});
  const fileInputRef = useRef(null);


  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail({ ...cateDetail, [name]: value });
  };

  const showModal = (e) => {
    console.log(e);
    setcateDetail(e);
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    if (!cateDetail?.name) {
      toast.error("please enter name");
    }
    if (!cateDetail?.description) {
      toast.error("please enter description");
    }
    if (!cateDetail?.amount) {
      toast.error("please enter amount");
    }
    if (!cateDetail?.duration_month) {
      toast.error("please enter duration_month");
    } else {
      const data = new FormData();
      data.append("page", 1);
      data.append("id", cateDetail?.id);
      data.append("name", cateDetail?.name);
      data.append("description", cateDetail?.description);
      data.append("amount", cateDetail?.amount);
      data.append("duration_month", cateDetail?.duration_month);
      await dispatch(allapiAction.update_subscription(data));
      // dispatch(adminAction.getRoleList('dhsg'))
      dispatch(allapiAction.get_subscription_plan(page));
      setIsModalOpen(false);
      return () => {};
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [cateDetailAdd, setcateDetailAdd] = useState({});

  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
  };

  const showModalAdd = (e) => {
    console.log(e);
    setcateDetail(e);
    setAddModalOpen(true);
  };
  const handleOkAdd = async () => {
    if (!cateDetailAdd?.name) {
      return toast.error("Please enter name");
    }
    if (!cateDetailAdd?.description) {
      return toast.error("Please enter description");
    }
    if (!cateDetailAdd?.amount) {
      return toast.error("Please enter amount");
    }
    if (!cateDetailAdd?.duration_month) {
      return toast.error("Please enter duration month");
    }
    if (!cateDetailAdd?.image) {
      return toast.error("Please upload an image");
    }
  
    try {
      const data = new FormData();
      data.append("name", cateDetailAdd.name);
      data.append("description", cateDetailAdd.description);
      data.append("amount", cateDetailAdd.amount);
      data.append("duration_month", cateDetailAdd.duration_month);
      data.append("image", cateDetailAdd.image);
  
      await dispatch(allapiAction.createSubscription(data));
  
      // Reset form state
      setcateDetailAdd({
        name: "",
        description: "",
        amount: "",
        duration_month: "",
        image: null,
      });
  
      // Clear the file input field
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear the file input
      }
      dispatch(allapiAction.get_subscription_plan(page));
      setAddModalOpen(false);

      toast.success("Subscription created successfully!");
    } catch (error) {
      console.error("Error while creating subscription:", error);
      toast.error("Failed to create subscription. Please try again.");
    }
  };
  

  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
    },
    {
      title: "Duration",
      dataIndex: "duration_month",
      key: "duration_month",
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    // },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Edit">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModal(item);
                }}
              >
                <SvgIcon.Edit className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            {/* <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                                hendleModalShow(item?.id)
                              }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showDeleteConfirm(item?.id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(allapiAction.get_subscription_plan(page));
    return () => {};
  }, []);

  // const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");
  const handleChange = (e) => {
    console.log(e);
    setsearch(e);
    setPage(1);
    if (e) {
      dispatch(allapiAction.allcategorySearch({ page: 1, search: e }));
      return () => {};
    } else {
      dispatch(allapiAction.get_subscription_plan(1));

      return () => {};
    }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);
    if (search) {
      dispatch(allapiAction.allcategorySearch({ page: e, search: search }));
      return () => {};
    } else {
      dispatch(allapiAction.get_subscription_plan(e));

      return () => {};
    }
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setcateDetailAdd((prev) => ({
        ...prev,
        image: file,
      }));
    }
  };
  
  
  return (
    <div>
      {/* update */}
      <Modal
        title="Update Subscription"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
          <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
    <label htmlFor="image-upload" className="form-label">
      Upload Image
    </label>
    <input
      type="file"
      id="image-upload"
      name="image"
      className="form-control"
      accept="image/*"
      value={cateDetail?.image}
      onChange={(e) => {
        handleImageUpload(e);
      }}
    />
  </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="name"
            name="name"
            value={cateDetail?.name}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="description"
            name="description"
            value={cateDetail?.description}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
            <input
            type="number"
            name="amount"
            className="form-control"
            value={cateDetail?.amount}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
            placeholder="Enter amount"
          />
          <input
            type="number"
            name="duration_month"
            className="form-control"
            value={cateDetail?.duration_month}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
            placeholder="Enter duration_month"
          />
        </Col>
      </Modal>

      {/* create */}
      <Modal
        title="Create Subscription"
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
      >
          <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
    <label htmlFor="image-upload" className="form-label">
      Upload Image
    </label>
    <input
  type="file"
  id="image-upload"
  name="image"
  className="form-control"
  accept="image/*"
  ref={fileInputRef} // Attach the ref here
  onChange={handleImageUpload}
/>

  </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="name"
            name="name"
            value={cateDetailAdd?.name}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="description"
            name="description"
            value={cateDetailAdd?.description}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          {/* <input
            autoComplete="new-off"
            className="form-control"
            placeholder="message"
            name="message"
            value={cateDetailAdd?.message}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          /> */}

          {/* <select
            name="amount"
            className="form-control"
            value={cateDetailAdd?.amount}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          >
            <option value={""}>Select type</option>
            <option value={"image"}>Image</option>
            <option value={"text"}>text</option>
          </select> */}
          <input
            type="number"
            name="amount"
            className="form-control"
            value={cateDetailAdd?.amount}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
            placeholder="Enter amount"
          />
          <input
            type="number"
            name="duration_month"
            className="form-control"
            value={cateDetailAdd?.duration_month}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
            placeholder="Enter duration_month"
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          {/* <input
            autoComplete="new-off"
            className="form-control"
            placeholder="message"
            name="message"
            value={cateDetailAdd?.message}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          /> */}

          {/* <select
            name="tip_type"
            className="form-control"
            value={cateDetailAdd?.tip_type}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          >
            <option value={""}>Select tip type</option>
            <option value={"free"}>Free</option>
            <option value={"premium"}>Premium</option>
          </select> */}
        </Col>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Subscription"}
            subHeading={`Total ${users_list?.total || 0} Subscription`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => showModalAdd()}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          dataSource={listdata}
          columns={columns}
          className={"table antd_table"}
          pagination={{
            current: page,
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.total ? users_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default Subscription;
