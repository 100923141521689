import React from "react";
import User from "../Component/User/User";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../Component/Home/Home";
import Login from "../Component/Login/Login";
import LayoutMain from "../Component/Layout";
import { GetToken } from "../Component/utils";
import Contact from "../Component/User/Contact";
import Category from "../Component/Category/Category";
import Quiz from "../Component/Quiz/Quiz";
import Question from "../Component/Question/Question";
import AddStoke from "../Component/Category/AddStoke";
import Subscription from "../Component/Category/Subscription";
import Transaction from "../Component/Category/Transaction";
import Notification from "../Component/Category/Notification";
import View_Stock_Details from "../Component/component/view_stock_details";
import Premium_Tips from "../Component/Category/premium_tips";

function PageRouter() {
  function RequireAuth({ children }) {
    const token = GetToken();
    return token ? children : <Navigate to="/login" replace />;
  }
  function AlreadyLogin({ children }) {
    const token = GetToken();
    return token ? <Navigate to="/" replace /> : children;
  }
  return (
    <div>
      <Routes>
        {/* <Route path="/forgot-password" element={<AlreadyLogin> <ForgotPassword /></AlreadyLogin>} /> */}
        <Route
          path="/login"
          element={
            <AlreadyLogin>
              <Login />
            </AlreadyLogin>
          }
        />
        <Route
          element={
            <RequireAuth>
              {" "}
              <LayoutMain />
            </RequireAuth>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/users" element={<User />} />
          <Route path="/users/contact/:id" element={<Contact />} />
          <Route path="/tips" element={<Category />} />
          <Route path="/premium_tips" element={<Premium_Tips />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/stocks" element={<AddStoke />} />
          <Route path="/details/:id" element={<View_Stock_Details />} />

          <Route path="/quiz" element={<Quiz />} />
          <Route path="/question" element={<Question />} />
          {/* <Route path="/faq-category" element={<FaqCategory />} /> */}
        </Route>
      </Routes>
    </div>
  );
}

export default PageRouter;
