import { ReactComponent as Plus } from './../assets/svg/plus.svg';
import { ReactComponent as Visibility } from './../assets/svg/visibility.svg';
import { ReactComponent as VisibilityOff } from './../assets/svg/visibility-off.svg';
import { ReactComponent as Pencil } from './../assets/svg/pencil.svg';
import { ReactComponent as ArrowDown } from './../assets/svg/arrow-down.svg';
import { ReactComponent as CurveLineDown } from './../assets/svg/curve-line-down.svg';
import { ReactComponent as CurveLineUp } from './../assets/svg/curve-line-up.svg';
import { ReactComponent as Filter } from './../assets/svg/filter.svg';
import { ReactComponent as FilterWhite } from './../assets/svg/filter_white.svg';
import { ReactComponent as LeftArrow } from './../assets/svg/left-arrow.svg';
import { ReactComponent as Logout } from './../assets/svg/logout.svg';
import { ReactComponent as Pdf } from './../assets/svg/pdf.svg';
import { ReactComponent as Notification } from './../assets/svg/notification.svg';
import { ReactComponent as Search } from './../assets/svg/search.svg';
import { ReactComponent as User } from './../assets/svg/user.svg';
import { ReactComponent as Add } from './../assets/svg/add.svg';
import { ReactComponent as FAQIcon } from './../assets/svg/faq-icon.svg';
import { ReactComponent as LoginVisual } from './../assets/svg/login-visual.svg';
import { ReactComponent as Edit } from './../assets/svg/edit.svg';
import { ReactComponent as HrIcon } from './../assets/svg/hricon.svg';
import { ReactComponent as PermissionIcon } from './../assets/svg/permission.svg';
import { ReactComponent as seoManagementIcon } from './../assets/svg/seoManagement.svg';
import { ReactComponent as seoIcon } from './../assets/svg/SEO.svg';
import { ReactComponent as contacts } from './../assets/svg/contacts.svg';
import { ReactComponent as deletebtn } from './../assets/svg/deletebtn.svg';


const SvgIcon = {
    User,
    VisibilityOff,
    Plus,
    Visibility,
    Pencil,
    ArrowDown,
    CurveLineDown,
    CurveLineUp,
    Filter,
    FilterWhite,
    LeftArrow,
    Logout,
    Pdf,
    Notification,
    Search,
    Add,
    FAQIcon,
    LoginVisual,
    Edit,
    HrIcon,
    PermissionIcon,
    seoManagementIcon,
    seoIcon,
    contacts,
    deletebtn,
    
};
export default SvgIcon