import React, { useEffect, useState } from "react";
import { Table, Tooltip, Col, Form, Input, Row } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";

function AddStoke() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);


  const users_list = useSelector((state) =>
    state?.allapi?.category_list ? state?.allapi?.category_list : {}
  );

  console.log( " add stock userList",users_list);

  const listdata = users_list?.data ? users_list?.data : [];
  console.log("list data for stock" ,listdata)

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [showDelelt, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const [cateID, setcateID] = useState("");

  const hendleDelete = async (e) => {
    console.log(e);
    // deleteProduct
    setShowDelete(false);
    await dispatch(allapiAction.deleteStocks({ id: e, page: page }));
    dispatch(allapiAction.get_stocks(page));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const hendleModalShow = (e) => {
    // setShowDelete(true)
    setcateID(e);
    setIsModalOpen(true);
  };

  const [cateDetail, setcateDetail] = useState({});

  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail({ ...cateDetail, [name]: value });
  };

  const showModal = (e) => {
    console.log(e);
    setcateDetail(e);
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    if (!cateDetail?.name) {
      return toast.error("please enter name");
    }
    if (!cateDetail?.exchange) {
      return toast.error("please enter exchange");
    }
    // if (!cateDetail?.type) {
    //   toast.error("please enter type");
    // }
    if (!cateDetail?.type) {
      toast.error("please enter type");
    } else {
      const data = new FormData();
      data.append("page", 1);
      data.append("id", cateDetail?.id);
      data.append("name", cateDetail?.name);
      data.append("exchange", cateDetail?.exchange);
      data.append("type", cateDetail?.type);
      data.append("buy", cateDetail?.buy);
      data.append("stop_loss", cateDetail?.stop_loss);
      await dispatch(allapiAction.updateStocks(data));
      // dispatch(adminAction.getRoleList('dhsg'))
      dispatch(allapiAction.get_stocks(page));
      setIsModalOpen(false);
      return () => {};
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [cateDetailAdd, setcateDetailAdd] = useState({});
  const [targetList, settargetList] = useState([]);

  const handleColorChange = (rowIndex, sizeIndex, field, value) => {
    settargetList((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex][field] = value;
      return updatedRows;
    });
  };

  const hendleToaddTraget = () => {
    settargetList([...targetList, { price: "" }]);
  };

  // const removeTarget = (i) => {
  //   const filtered = targetList.filter((item, index) => index !== i);
  //   settargetList(filtered);
  // };
  const removeTarget = (index) => {
    // setRows((prevRows) => {
    //   const updatedRows = [...prevRows]
    //   updatedRows[rowIndex].sizeProduct.splice(sizeIndex, 1)
    //   return updatedRows
    // })
    console.log(index);
    const filtered = targetList.filter((item, i) => i !== index);
    console.log(filtered);

    settargetList((prevRows) => prevRows.filter((row, i) => i !== index));
  };
  // const hendleToChangeTarget = (e)=>{
  //   const { name, value } = e.target;

  // }

  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
  };

  const showModalAdd = (e) => {
    console.log(e);
    setcateDetail(e);
    setAddModalOpen(true);
  };
  const handleOkAdd = async () => {
    if (!cateDetailAdd?.name) {
      return toast.error("please enter name");
    }
    if (!cateDetailAdd?.exchange) {
      return toast.error("please enter exchange");
    }
    // if (!cateDetailAdd?.type) {
    //   return  toast.error("please enter type");
    // }
    if (!cateDetailAdd?.type) {
      return toast.error("please enter type");
    } else {
      const data = new FormData();
      data.append("page", 1);
      data.append("name", cateDetailAdd?.name);
      // data.append("message", cateDetailAdd?.message);
      data.append("exchange", cateDetailAdd?.exchange);
      data.append("type", cateDetailAdd?.type);
      data.append("buy", cateDetailAdd?.buy);
      data.append("stop_loss", cateDetailAdd?.stop_loss);
      // data.append("target", targetList);
      console.log(targetList);
     // Append targetList properly to FormData
  targetList.forEach((item, index) => {
    Object.entries(item).forEach(([key, value]) => {
      data.append(`target[${index}][${key}]`, value);
    });
  });

  // Debugging targetList for verification
  targetList?.forEach((data) => {
    console.log("Type of price:", typeof data?.price); 
    console.log("Price value:", data?.price);      
  });
      await dispatch(allapiAction.addStocks(data));

      setcateDetailAdd({
        name: "",
        message: "",
        exchange: "",
        type: "",
        buy: "",
        stop_loss: "",
      });
      settargetList([]);
      dispatch(allapiAction.get_stocks(1));
      // dispatch(adminAction.getRoleList('dhsg'))
      setAddModalOpen(false);
      return () => {};
    }
  };

  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Exchange",
      dataIndex: "exchange",
      key: "exchange",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Buy",
      dataIndex: "buy",
      key: "buy",
    },
    {
      title: "Stop Loss",
      dataIndex: "stop_loss",
      key: "stop_loss",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    // {
    //   title: "Time",
    //   dataIndex: "time",
    //   key: "time",
    // },
    // {
    //   title: "Tip type",
    //   dataIndex: "tip_type",
    //   key: "tip_type",
    // },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    // },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Edit">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModal(item);
                }}
              >
                <SvgIcon.Edit className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            {/* <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                                hendleModalShow(item?.id)
                              }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showDeleteConfirm(item?.id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            {/* view all details of stock */}
            <Tooltip title="View Details">
  <span
    className="cursor-pointer ml-3"
    onClick={() => {
      viewDetails(item?.id);
    }}
  >
    <SvgIcon.Visibility className="scale-icon view-icon" />
  </span>
</Tooltip>

          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(allapiAction.get_stocks(1));

    return () => {};
  }, []);

  // const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");
  const handleChange = (e) => {
    console.log(e);
    setsearch(e);
    setPage(1);
    if (e) {
      dispatch(allapiAction.allcategorySearch({ page: 1, search: e }));
      return () => {};
    } else {
      dispatch(allapiAction.get_stocks(1));

      return () => {};
    }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);
    if (search) {
      dispatch(allapiAction.allcategorySearch({ page: e, search: search }));
      return () => {};
    } else {
      dispatch(allapiAction.get_stocks(e));

      return () => {};
    }
  };
  const viewDetails = (id) => {
    navigate(`/details/${id}`); 
  };
  return (
    <div>
      {/* update */}
      <Modal
        title="Update Stock"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="name"
            name="name"
            value={cateDetail?.name}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="exchange"
            name="exchange"
            value={cateDetail?.exchange}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          {/* <input
            autoComplete="new-off"
            className="form-control"
            placeholder="message"
            name="message"
            value={cateDetail?.message}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          /> */}

          <select
            name="type"
            className="form-control"
            value={cateDetail?.type}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          >
            <option value={""}>Select type</option>
            <option value={"Shortterm"}>Shortterm</option>
            <option value={"Longterm"}>Longterm</option>
          </select>
        </Col>

        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="buy"
            name="buy"
            value={cateDetail?.buy}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="stop_loss"
            name="stop_loss"
            value={cateDetail?.stop_loss}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
      </Modal>

      {/* create */}
      <Modal
        title="Create Stock"
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="name"
            name="name"
            value={cateDetailAdd?.name}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="exchange"
            name="exchange"
            value={cateDetailAdd?.exchange}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>

        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          <select
            name="type"
            className="form-control"
            value={cateDetailAdd?.type}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          >
            <option value={""}>Select type</option>
            <option value={"Shortterm"}>Shortterm</option>
            <option value={"Longterm"}>Longterm</option>
          </select>
        </Col>

        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="buy"
            name="buy"
            value={cateDetailAdd?.buy}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="stop_loss"
            name="stop_loss"
            value={cateDetailAdd?.stop_loss}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          <label>Target</label>
        </Col>
        {targetList &&
          targetList?.map((data, i) => {
            return (
              <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
                <Row>
                  <Col lg={18} md={18} sm={18} xs={18}>
                    {" "}
                    <input
                      autoComplete="new-off"
                      className="form-control"
                      placeholder="price"
                      name="price"
                      type="number"
                      value={targetList[i]?.price}
                      onChange={(e) => {
                        // handleColorChange(e);
                        handleColorChange(
                          i,
                          "sizeIndex",
                          "price",
                          e.target.value
                        );
                      }}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={6} xs={6}>
                    {" "}
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        removeTarget(i);
                      }}
                    >
                      Remove
                    </button>
                  </Col>
                </Row>
              </Col>
            );
          })}
        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          <button
            className="btn btn-primary"
            onClick={() => {
              hendleToaddTraget();
            }}
          >
            Add Target
          </button>
        </Col>
        {/* <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
         

          <select
            name="tip_type"
            className="form-control"
            value={cateDetailAdd?.tip_type}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          >
            <option value={""}>Select tip type</option>
            <option value={"free"}>Free</option>
            <option value={"premium"}>Premium</option>
          </select>
        </Col> */}
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Stocks"}
            subHeading={`Total ${users_list?.total || 0} Stocks`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => showModalAdd()}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          dataSource={listdata}
          columns={columns}
          className={"table antd_table"}
          pagination={{
            current: page,
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.total ? users_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default AddStoke;
