const RegEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const PasswordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d!@#$%^&*]{8,16}$/i;
export function validateLogin(values) {
  var errors = {};
  if (!values?.Email) {
    errors.Email = "Email Required";
  } else if (!RegEmail.test(values.Email)) {
    errors.Email = "Invalid email address";
  }
  if (!values?.Password) {
    errors.Password = "Password Required";
  }
  return errors;
}
export function validateForgetPassword(values) {
  var errors = {};
  if (!values?.Email) {
    errors.Email = "Email Required";
  } else if (!RegEmail.test(values.Email)) {
    errors.Email = "Invalid email address";
  }
  return errors;
}
export function newValidateLogin(values) {
  var errors = {};
  if (!values?.Email) {
    errors.Email = "Email Required";
  } else if (!RegEmail.test(values.Email)) {
    errors.Email = "Invalid email address";
  }
  if (!values?.Password) {
    errors.Password = "Password Required";
  }
  return errors;
}
