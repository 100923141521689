export const URL = {
  // dev

  // API_BASE_URL: "http://192.168.0.13:8000",
  // API_BASE_URL: "https://appdevelopmentcompanyjaipur.com/aurra",
  // API_BASE_URL: "https://elite.bestsoftwareagency.com",
  API_BASE_URL: "https://tradingtipsapi.devhubtech.com",
  // API_BASE_URL: "http://192.168.0.12:5500",
  // API_BASE_URL: "http://gamesdeveloper.in:3002",

  

  // apipublic

  login_admin: "/api/admin/login",
  all_category_main: "/api/admin/get_tips",
  all_premium_tips: "/api/admin/get_premium_tips",
  get_subscription_plan: "/api/admin/get_subscription_plan",
  update_sub : "/api/admin/update_subscription_plan",
  get_transactions: "/api/admin/get_transactions",
  notification: "/api/admin/notification",
  get_stocks: "/api/admin/get_stocks",
  get_stocks_details: "/api/admin/getStockDetails",
  updateStocksTarget : "/api/admin/update_stocks_target",
  deleteStocksTarget : "/api/admin/delete_stocks_target",
  addStocksTarget : "/api/admin/add_stocks_target",
  create_quiz_category: "/api/admin/add_tips",
  create_premium : "/api/admin/add_premium_tips",
  delete_premium_tip : "/api/admin/delete_premium_tips",
  update_premium_tip : "/api/admin/update_premium_tips",
  addStocks: "/api/admin/add_stocks",
  delete_category: "/api/admin/delete_tips",
  deleteStocks: "/api/admin/delete_stocks",
  update_category: "/api/admin/update_tips",
  updateStocks: "/api/admin/update_stocks",
  users: "/api/admin/get_users",
  user_contact: "/api/user_contact",
  all_category: "/api/all_category",
  quiz_list: "/api/quiz_list",
  create_quiz: "/api/create_quiz",
  delete_quiz: "/api/delete_quiz",
  update_quiz: "/api/update_quiz",
  questions_list: "/api/questions_list",
  delete_question: "/api/delete_question",
  update_question: "/api/update_question",
  create_quiz_question: "/api/create_quiz_question",
  dashboard: "/api/dashboard",
  user_allcontact: "/api/user_allcontact",
  user_inactive: "/api/user_inactive",
  quiz_active: "/api/quiz_active",
changeStatus : "/api/admin/activeInactiveUsers",
  createcategories: "/apiVendor/createcategories",
  getCategory: "/publicApi/getCategory",
  getsubsubcate: "/publicApi/getsubsubcate",
  getsubsubcateHome: "/publicApi/getsubsubcateHome",
  signupSeller: "/publicApi/signupSeller",
  otpVerify: "/publicApi/otpVerify",
  productDetails: "/publicApi/productDetails",
  getsellerProductListPublice: "/publicApi/getsellerProductListPublice",
  gettagsProductListPublice: "/publicApi/gettagsProductListPublice",
  getsellerProductListPublicefilCate:
    "/publicApi/getsellerProductListPublicefilCate",
  getsellerProductListPublicebycategory:
    "/publicApi/getsellerProductListPublicebycategory",
  getsellerprofile: "/publicApi/getsellerprofile",
  whatsappinquiresubmit: "/publicApi/whatsappinquiresubmit",
  getsubsubCategoryList: "/publicApi/getsubsubCategoryList",
  createSubscription: "/api/admin/add_subscription_plan", // change this 
  delete_sub : "/api/admin/delete_subscription_plan",
  getPages: "/publicApi/getPagesPu",
  emailenquirysubmit: "/publicApi/emailenquirysubmit",
  pageDetails: "/publicApi/pageDetails",
  searchProductpub: "/publicApi/searchProductpub",
  sendOtpSeller: "/publicApi/sendOtpSeller",
  otpVerifyseller: "/publicApi/otpVerifyseller",
  signinSellereSecurite: "/publicApi/signinSellereSecurite",
  footerPagepageListetPu: "/publicApi/footerPagepageListetPu",
  productclick: "/publicApi/productclick",
  getHomePro: "/publicApi/getHomePro",
  getSellerListPublic: "/publicApi/getSellerListPublic",
  getsellerProductListPublicebymaincategory2:
    "/publicApi/getsellerProductListPublicebymaincategory2",

  // apiAdmin
  signinSeller: "/publicApi/signinSeller",
  getsellerProductList: "/sellerApi/getsellerProductList",
  createSellerProduct: "/sellerApi/createSellerProduct",
  searchSellerProduct: "/sellerApi/searchSellerProduct",
  deleteSellerProduct: "/sellerApi/deleteSellerProduct",
  createBussnissPro: "/sellerApi/createBussnissPro",
  getBussProfile: "/sellerApi/getBussProfile",
  updateWhatsaapNumber: "/sellerApi/updateWhatsaapNumber",
  verifyWhatsapp: "/sellerApi/verifyWhatsapp",
  updateBussLogo: "/sellerApi/updateBussLogo",
  updateBussProfile: "/sellerApi/updateBussProfile",
  getPackageList: "/sellerApi/getPackageList",
  createPurchase: "/sellerApi/createPurchase",
  getsellerTransaction: "/sellerApi/getsellerTransaction",
  getprofile: "/sellerApi/getprofile",
  updatesellerProduct: "/sellerApi/updatesellerProduct",
  getSellerTicketList: "/sellerApi/getSellerTicketList",
  createSellerTickets: "/sellerApi/createSellerTickets",
  getmessageSupportList: "/sellerApi/getmessageSupportList",
  createMessage: "/sellerApi/createMessage",
  deleteacountByseller: "/sellerApi/deleteacountByseller",
  copyProduct: "/sellerApi/copyProduct",
  getInvoice: "/sellerApi/getInvoice",
  getEmailenquirySeller: "/sellerApi/getEmailenquirySeller",
  getsellerWhatsappinquire: "/sellerApi/getsellerWhatsappinquire",
  searchtags: "/sellerApi/searchtags",
  passwordChange: "/sellerApi/passwordChange",
  topViewnewSeller: "/sellerApi/topViewnewSeller",
  sellerDashboardState: "/sellerApi/sellerDashboardState",
  getExpireDate: "/sellerApi/getExpireDate",
  getsliderList: "/publicApi/getsliderList2",
  getBlogListPub: "/publicApi/getBlogListPub",
  getBlogDetails: "/publicApi/getBlogDetails",
  gethomePop: "/publicApi/gethomePop",
  getlistfaqs: "/publicApi/getlistfaqs",
  getInvoceDetails: "/publicApi/getInvoceDetails",
};
