import React, { useEffect, useState } from "react";
import { Col, Input, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import { ExportToExcel } from "../component/ExportToExcel";
import { URL } from "../../Redux/common/url";
import Api from "../../Redux/common/api";
function Contact() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const users_contact_list = useSelector((state) =>
    state?.allapi?.users_contact_list ? state?.allapi?.users_contact_list : {}
  );

  console.log(users_contact_list);
  console.log(id);

  const listdata = users_contact_list?.data ? users_contact_list?.data : [];

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
    // {
    //   title: "Student Mobile",
    //   dataIndex: "student_mobile",
    //   key: "student_mobile",
    // },
    // {
    //   title: "Parent Name",
    //   dataIndex: "parent_name",
    //   key: "parent_name",
    // },
    // {
    //   title: "Parent Mobile",
    //   dataIndex: "parent_mobile",
    //   key: "parent_mobile",
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    // },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "action",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <Tooltip title="Edit">
    //         <span
    //           className="cursor-pointer"
    //           onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           <SvgIcon.contacts className="scale-icon edit-icon" />
    //         </span>
    //       </Tooltip>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    dispatch(allapiAction.usercontact({ page: 1, user_id: id }));

    return () => {};
  }, []);

  const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");
  const handleChange = (e) => {
    console.log(e);
    setsearch(e);
    setPage(1);
    if (e) {
      dispatch(
        allapiAction.usercontactser({ page: 1, user_id: id, search: e })
      );
      return () => {};
    } else {
      dispatch(allapiAction.usercontact({ page: 1, user_id: id }));

      return () => {};
    }
    // usercontactser
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    if (search) {
      dispatch(
        allapiAction.usercontactser({ page: e, user_id: id, search: search })
      );
      return () => {};
    } else {
      dispatch(allapiAction.usercontact({ page: e, user_id: id }));

      return () => {};
    }
  };
  const fileName = "Application report";

  const hendletoExportExl = async () => {
    // user_allcontact
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      const response = await Api.get(
        `${URL.user_allcontact}?user_id=${id}`,
        config
      );

      if (response?.data?.status) {
        ExportToExcel(
          response?.data?.data?.contact,
          response?.data?.data?.user?.name
        );
      } else {
      }
      console.log(response?.data);
      //
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div className="flex">
        <div>
          <HeadingBack
            title={"User Contact"}
            subHeading={`Total ${users_contact_list?.total || 0} Users Contact`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>
        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => hendletoExportExl()}>
              Export
              {/* <SvgIcon.Add width={40} height={40} className="cursor-pointer" /> */}
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: page,
            pageSize: 50,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_contact_list?.total ? users_contact_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default Contact;
