import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "../common/api";
import {
  LOADING,
  CREATE_CATEGORIES,
  GET_CATEGORIES,
  GET_SUB_SUB_CATEGORIES,
  GET_SELLER_PRODUCT_LIST,
  GET_SUB_SUB_CATE_LIST,
  OTP_BOX,
  GET_BUSS_PRO,
  GET_PACKAGE_LIST,
  GET_SELLER_TANSACTION,
  GET_PROFILE,
  GET_SELLER_TICKET_LIST,
  GET_MESSAGE_LISTS,
  GET_INVOICE,
  GET_PRODUCT_DETAILS,
  GET_SELLER_PRODUCT_LIST_PUBLICE,
  GET_TAGS_PRODUCT_LIST_PUBLICE,
  GET_SELLER_PRODUCT_LIST_PUBLICE_BY_CATEGORY,
  GET_SELLER_PROFILE,
  GET_SUB_SUB_CATE_LIST_FIL,
  GET_PAGES,
  GET_EMAIL_QU,
  GET_WHATSAPP_QU,
  PAGE_DETAILS,
  SEARCH_TAGS,
  SEARCH_PRODUCT_PUB,
  GET_FOOTER_DATA_ALL,
  GET_HOME_ALL_PRO,
  TOP_VIEW_ALL_TYPE,
  SELLER_LIST,
  DESHBOARD_STATE,
  SLIDER_LIST,
  BLOG_LIST,
  BLOG_DETAILS,
  GET_EXPIRE_DATE,
  HOME_POPS,
  FAQS_LIST,
  USERS_LIST,
  USERS_CONTACT_LIST,
  CATEGORY_LIST,
  QUIZ_LIST,
  QUESTION_LIST,
  DESHBOARD,
} from "../common/constant";
// const Token = require("../../Utils/Auth/Token");

const loading = (data) => {
  return { type: LOADING, payload: data };
};
const createcategoriesAction = (data) => {
  return { type: CREATE_CATEGORIES, payload: data };
};
const getcategoriesAction = (data) => {
  return { type: GET_CATEGORIES, payload: data };
};
const getsubsusbcategoriesAction = (data) => {
  return { type: GET_SUB_SUB_CATEGORIES, payload: data };
};
const getsubsusbcategoriesfillAction = (data) => {
  return { type: GET_SUB_SUB_CATE_LIST_FIL, payload: data };
};
const getsellerProductListAction = (data) => {
  return { type: GET_SELLER_PRODUCT_LIST, payload: data };
};
const getsellerProductListPublicAction = (data) => {
  return { type: GET_SELLER_PRODUCT_LIST_PUBLICE, payload: data };
};
const gettagsProductListPublicAction = (data) => {
  return { type: GET_TAGS_PRODUCT_LIST_PUBLICE, payload: data };
};
const getsellerProductListPublicbycategoryAction = (data) => {
  return { type: GET_SELLER_PRODUCT_LIST_PUBLICE_BY_CATEGORY, payload: data };
};
const getsubsusbcateListAction = (data) => {
  return { type: GET_SUB_SUB_CATE_LIST, payload: data };
};
const otpBoxAction = (data) => {
  return { type: OTP_BOX, payload: data };
};
// const otpBoxForgotAction = (data) => {
//   return { type: OTP_BOX_FORGOT, payload: data };
// };
const getBussnissProfileAction = (data) => {
  return { type: GET_BUSS_PRO, payload: data };
};
const getpackageListAction = (data) => {
  return { type: GET_PACKAGE_LIST, payload: data };
};
const getticketListAction = (data) => {
  return { type: GET_SELLER_TICKET_LIST, payload: data };
};
const gettansactionListAction = (data) => {
  return { type: GET_SELLER_TANSACTION, payload: data };
};
const getprofileAction = (data) => {
  return { type: GET_PROFILE, payload: data };
};
const getmessageListAction = (data) => {
  return { type: GET_MESSAGE_LISTS, payload: data };
};
const getinvoiceAction = (data) => {
  return { type: GET_INVOICE, payload: data };
};
const getproductDetailsAction = (data) => {
  return { type: GET_PRODUCT_DETAILS, payload: data };
};
const getSellerDetailsAction = (data) => {
  return { type: GET_SELLER_PROFILE, payload: data };
};
const getPagesAction = (data) => {
  return { type: GET_PAGES, payload: data };
};
const getemailQuAction = (data) => {
  return { type: GET_EMAIL_QU, payload: data };
};
const getWhatsappQuAction = (data) => {
  return { type: GET_WHATSAPP_QU, payload: data };
};
const getpageDetailsAction = (data) => {
  return { type: PAGE_DETAILS, payload: data };
};
const getsearchtagssAction = (data) => {
  return { type: SEARCH_TAGS, payload: data };
};
const getsearchProductPubAction = (data) => {
  return { type: SEARCH_PRODUCT_PUB, payload: data };
};
const getfooterAllDataPubAction = (data) => {
  return { type: GET_FOOTER_DATA_ALL, payload: data };
};
const gethomeAllProAction = (data) => {
  return { type: GET_HOME_ALL_PRO, payload: data };
};
const topViewAllTypeAction = (data) => {
  return { type: TOP_VIEW_ALL_TYPE, payload: data };
};
const getSellerListPublicAction = (data) => {
  return { type: SELLER_LIST, payload: data };
};
const sellerDashboardStateAction = (data) => {
  return { type: DESHBOARD_STATE, payload: data };
};
const sliderListAction = (data) => {
  return { type: SLIDER_LIST, payload: data };
};
const blogListAction = (data) => {
  return { type: BLOG_LIST, payload: data };
};
const blogDetailsAction = (data) => {
  return { type: BLOG_DETAILS, payload: data };
};
const getExpireDateAction = (data) => {
  return { type: GET_EXPIRE_DATE, payload: data };
};
const homepopAction = (data) => {
  return { type: HOME_POPS, payload: data };
};

// new
const userListAction = (data) => {
  return { type: USERS_LIST, payload: data };
};
const userContactListAction = (data) => {
  return { type: USERS_CONTACT_LIST, payload: data };
};

const faqsLsitAction = (data) => {
  return { type: FAQS_LIST, payload: data };
};
const categoryLsitAction = (data) => {
  return { type: CATEGORY_LIST, payload: data };
};
const questionLsitAction = (data) => {
  return { type: QUESTION_LIST, payload: data };
};
const deshboardAction = (data) => {
  return { type: DESHBOARD, payload: data };
};
const quizLsitAction = (data) => {
  return { type: QUIZ_LIST, payload: data };
};

export const userLogin = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.login_admin}`, data);
      if (response?.status == 200) {
        // toast.success(response?.data?.message);
        console.log(response?.data);
        if (response?.data?.success == true) {
          // dispatch(otpBoxAction(true));
          localStorage.setItem("admin_token", response?.data?.data?.auth_token);
          navigate("/");
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
        // otpBoxAction
        // Token.setToken(response?.data?.api_token);
        // setTimeout(navigate("/vendor"), 1000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const dashboard = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.dashboard}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(deshboardAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.users}?page=${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const user_inactive = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // user_inactive
      const response = await api.post(`${URL.user_inactive}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);
        const responses = await api.get(
          `${URL.users}?page=${data?.page}`,
          config
        );
        dispatch(userListAction(responses?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
// change status of user

export const changeStatusUser = (data) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      if (!token) {
        toast.error("Authentication token is missing. Please log in again.");
        return;
      }

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.changeStatus}`, data, config);
      console.log("The response is:", response);

      if (response.status === 200) {
        toast.success("User status updated successfully!");
        // Optionally, dispatch an action to update the redux store
        // dispatch({ type: "UPDATE_USER_STATUS", payload: response.data });
      } else {
        toast.error("Failed to update user status.");
      }
    } catch (error) {
      console.error("Error updating user status:", error);
      toast.error("An error occurred while updating user status.");
    }
  };
};

export const getuserListSearch = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.users}?page=${data?.page}&search=${data?.search}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const get_stocks = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.get_stocks}?page=${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
// get stock detail by id
export const getStockDetails = (id) => {
  console.log("hitting details");
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("admin_token");
      if (!token) {
        toast.error("Authentication token not found!");
        dispatch(loading(false));
        return;
      }

      // Configure headers
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Dispatch loading state
      dispatch(loading(true));

      // Make the API request
      const response = await api.post(
        `${URL.get_stocks_details}?id=${id}`,
        {},
        config
      );
      console.log("The details of stock are:", response);

      // Handle response success
      if (response?.data?.success) {
        dispatch(categoryLsitAction(response?.data?.data));
        toast.success(response?.data?.message);
        return response?.data;
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      // Handle errors gracefully
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      toast.error(errorMessage);
      console.error(error);
    } finally {
      // Reset loading state
      dispatch(loading(false));
    }
  };
};
// update stock target

export const updateStocksTarget = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.updateStocksTarget}`,
        data,
        config
      );
      // const response = await api.get(
      //   `${URL.update_category}?page=${data?.page}&category_id=${data?.category_id}&name=${data?.name}`,
      //   config
      // );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};

export const notification = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.notification}?page=${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const get_transactions = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.get_transactions}?page=${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const get_subscription_plan = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.get_subscription_plan}?page=${data}`,
        config
      );
      console.log("api response", response);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      // dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const allcategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.all_category_main}?page=${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
// get all premium tips

export const allpremium_tips = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.all_premium_tips}?page=${data}`,
        config
      );
      console.log("all premium list is", response);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const allcategorySearch = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.all_category_main}?page=${data?.page}&search=${data?.search}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const deleteStocks = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.deleteStocks}`, data, config);
      // const response = await api.get(
      //   `${URL.delete_category}?page=${data?.page}&category_id=${data?.category_id}`,
      //   config
      // );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      return error?.response?.data;
      // toast.error(error?.response?.data?.message);
    }
  };
};
// delete stock target
export const deleteStocksTarget = (id) => {
  // Take only the id as argument
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      // Send id in the body
      const data = { id }; // Pass id as an object

      const response = await api.post(
        `${URL.deleteStocksTarget}`,
        data,
        config
      ); // API request

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        dispatch(categoryLsitAction(response?.data?.data)); // Update your state or dispatch any other actions
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      return error?.response?.data;
    }
  };
};

export const delete_category = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.delete_category}`, data, config);
      // const response = await api.get(
      //   `${URL.delete_category}?page=${data?.page}&category_id=${data?.category_id}`,
      //   config
      // );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));

      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
// delete subscription plan

export const delete_subscription = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.delete_sub}`, data, config);
      // const response = await api.get(
      //   `${URL.delete_category}?page=${data?.page}&category_id=${data?.category_id}`,
      //   config
      // );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));

      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};

// update subscription
export const update_subscription = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.update_sub}`, data, config);
      // const response = await api.get(
      //   `${URL.update_category}?page=${data?.page}&category_id=${data?.category_id}&name=${data?.name}`,
      //   config
      // );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const update_category = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.update_category}`, data, config);
      // const response = await api.get(
      //   `${URL.update_category}?page=${data?.page}&category_id=${data?.category_id}&name=${data?.name}`,
      //   config
      // );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};

// update premium tips

export const update_premium_tips = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.update_premium_tip}`,
        data,
        config
      );
      // const response = await api.get(
      //   `${URL.update_category}?page=${data?.page}&category_id=${data?.category_id}&name=${data?.name}`,
      //   config
      // );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const updateStocks = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.updateStocks}`, data, config);
      // const response = await api.get(
      //   `${URL.update_category}?page=${data?.page}&category_id=${data?.category_id}&name=${data?.name}`,
      //   config
      // );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const addStocks = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.addStocks}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
// add stock target
export const addStockTarget = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.addStocksTarget}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const create_quiz_category = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.create_quiz_category}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};

// create premium tips
export const create_premium_tips = (data, history) => {
  console.log("hitttt premium tips apii");
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.create_premium}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
// delete premium tips
export const delete_premium_tips = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.delete_premium_tip}`,
        data,
        config
      );
      // const response = await api.get(
      //   `${URL.delete_category}?page=${data?.page}&category_id=${data?.category_id}`,
      //   config
      // );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));

      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};

export const create_quiz = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.create_quiz}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(quizLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const update_quiz = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.update_quiz}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(quizLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const delete_quiz = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.delete_quiz}?page=${data?.page}&category_id=${data?.category_id}&type=${data?.type}&quiz_id=${data?.quiz_id}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(quizLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const quiz_list = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.quiz_list}?page=${data?.page}&category_id=${data?.category_id}&type=${data?.type}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(quizLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const quiz_listSearch = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.quiz_list}?page=${data?.page}&category_id=${data?.category_id}&type=${data?.type}&search=${data?.search}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(quizLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const questionsLlist = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.questions_list}?page=${data?.page}&quiz_id=${data?.quiz_id}&type=${data?.type}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(questionLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const questionsLlistSearch = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.questions_list}?page=${data?.page}&quiz_id=${data?.quiz_id}&type=${data?.type}&search=${data?.search}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(questionLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const delete_question = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.delete_question}?page=${data?.page}&quiz_id=${data?.quiz_id}&type=${data?.type}&question_id=${data?.question_id}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(questionLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const update_question = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.update_question}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(questionLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const create_quiz_question = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.create_quiz_question}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(questionLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const usercontact = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.user_contact}?page=${data?.page}&user_id=${data?.user_id}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(userContactListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        dispatch(userContactListAction({}));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const usercontactser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.user_contact}?page=${data?.page}&user_id=${data?.user_id}&search=${data?.search}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(userContactListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        dispatch(userContactListAction({}));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

// old

export const getlistfaqs = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getlistfaqs}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(faqsLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const gethomePop = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.gethomePop}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(homepopAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getBlogListPub = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getBlogListPub}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(blogListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getExpireDate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getExpireDate}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getExpireDateAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const sellerDashboardState = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.sellerDashboardState}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(sellerDashboardStateAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getSellerListPublic = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getSellerListPublic}`,
        data,
        config
      );
      dispatch(getSellerListPublicAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const topViewnewSeller = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.topViewnewSeller}`, data, config);
      dispatch(topViewAllTypeAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getsliderList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsliderList}`, config);

      dispatch(sliderListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getBlogDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getBlogDetails}`, data, config);
      dispatch(blogDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getHomePro = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getHomePro}`, data, config);
      dispatch(gethomeAllProAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const footerPagepageListetPu = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.footerPagepageListetPu}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getfooterAllDataPubAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const passwordChange = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.passwordChange}`, data, config);

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));

        // dispatch(getsearchProductPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const sendOtpSeller = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.sendOtpSeller}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        dispatch(otpBoxAction(true));

        // dispatch(getsearchProductPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const searchProductpub = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.searchProductpub}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsearchProductPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createSellerProducsdft = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createSellerProduct}`,
        data,
        config
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const searchtags = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.searchtags}`, data, config);
      dispatch(getsearchtagssAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const pageDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.pageDetails}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getpageDetailsAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getPages = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getPages}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getPagesAction(response?.data?.data));
      } else {
        dispatch(getPagesAction(response?.data?.data));
        // toast.error(response?.data?.message);
      }
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getEmailenquirySeller = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getEmailenquirySeller}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getemailQuAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerWhatsappinquire = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsellerWhatsappinquire}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getWhatsappQuAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubsubCategoryList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsubsubCategoryList}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsubsusbcategoriesfillAction(response?.data?.data));
      } else {
        dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const productDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.productDetails}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getproductDetailsAction(response?.data?.data));
        return response;
      } else {
        dispatch(getsellerProductListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const emailenquirysubmit = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.emailenquirysubmit}`,
        data,
        config
      );
      // dispatch(createcategoriesAction(response?.data?.data));
      toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const whatsappinquiresubmit = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.whatsappinquiresubmit}`,
        data,
        config
      );
      // dispatch(createcategoriesAction(response?.data?.data));
      toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const createcategories = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createcategories}`, data, config);
      dispatch(createcategoriesAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const productclick = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.productclick}`, data, config);
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        // toast.error(response?.data?.message);
      }
      // dispatch(getSellerDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";

      toast.success();
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const createSubscription = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createSubscription}`,
        data,
        config
      );
      console.log("the response is ", response);
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }
      // dispatch(getSellerDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";

      toast.success();
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerprofile = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getsellerprofile}`, data, config);
      dispatch(getSellerDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const gettagsProductListPublice = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.gettagsProductListPublice}`,
        data,
        config
      );
      dispatch(gettagsProductListPublicAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductListPublice = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getsellerProductListPublice}`,
        data,
        config
      );
      dispatch(getsellerProductListPublicAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductListPublicefilCate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getsellerProductListPublicefilCate}`,
        data,
        config
      );
      dispatch(getsellerProductListPublicAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getsellerProductListPublicebymaincategory2 = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getsellerProductListPublicebymaincategory2}`,
        data,
        config
      );
      dispatch(
        getsellerProductListPublicbycategoryAction(response?.data?.data)
      );
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductListPublicebycategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getsellerProductListPublicebycategory}`,
        data,
        config
      );
      dispatch(
        getsellerProductListPublicbycategoryAction(response?.data?.data)
      );
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const createSellerProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createSellerProduct}`,
        data,
        config
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const copyProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.copyProduct}`, data, config);

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updatesellerProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.updatesellerProduct}`,
        data,
        config
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const searchSellerProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.searchSellerProduct}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }
      // dispatch(getsellerProductListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteSellerProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.deleteSellerProduct}`,
        data,
        config
      );
      dispatch(getsellerProductListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsellerProductList}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        dispatch(getsellerProductListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getCategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getCategory}`, data, config);

      dispatch(getcategoriesAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubsubcateList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsubsubcate}`, data, config);

      dispatch(getsubsusbcateListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubsubcateHome = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsubsubcateHome}`, data, config);

      dispatch(getsubsusbcategoriesAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

// export const signinSeller = (data, navigate) => {
//   return async (dispatch) => {
//     try {
//       const response = await api.post(`${URL.signinSeller}`, data);
//       if (response?.status == 200) {
//         toast.success(response?.data?.message);

//         Token.setToken(response?.data?.api_token);

//         const lsValue = JSON.stringify(response?.data?.data);
//         Token.setUserDetail(lsValue);
//         setTimeout(navigate("/vendor"), 3000);
//         dispatch(loading(false));
//       } else {
//         toast.error(response?.data?.message);
//       }
//     } catch (error) {
//       dispatch(loading(false));
//       toast.error(error?.response?.data?.message);
//     }
//   };
// };
// export const signinSellereSecurite = (data, navigate) => {
//   return async (dispatch) => {
//     try {
//       const response = await api.post(`${URL.signinSellereSecurite}`, data);
//       if (response?.status == 200) {
//         // toast.success(response?.data?.message);

//         Token.setToken(response?.data?.api_token);
//         const lsValue = JSON.stringify(response?.data?.data);
//         Token.setUserDetail(lsValue);
//         setTimeout(navigate("/vendor"), 1000);
//         dispatch(loading(false));
//       } else {
//         toast.error(response?.data?.message);
//       }
//     } catch (error) {
//       dispatch(loading(false));
//       toast.error(error?.response?.data?.message);
//     }
//   };
// };

export const otpVerify = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.otpVerify}`, data);
      if (response?.status == 200) {
        // toast.success(response?.data?.message);

        if (response?.data?.success == true) {
          dispatch(otpBoxAction(false));
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
        // otpBoxAction
        // Token.setToken(response?.data?.api_token);
        setTimeout(navigate("/login"), 1000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const otpVerifyseller = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.otpVerifyseller}`, data);
      if (response?.status == 200) {
        // toast.success(response?.data?.message);

        if (response?.data?.success == true) {
          dispatch(otpBoxAction(false));
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
        // otpBoxAction
        // Token.setToken(response?.data?.api_token);
        setTimeout(navigate("/login"), 1000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const createBussnissPro = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.createBussnissPro}`, data, config);
      dispatch(getBussnissProfileAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const updateBussLogo = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.updateBussLogo}`, data, config);
      dispatch(getBussnissProfileAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const updateBussProfile = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.updateBussProfile}`, data, config);
      dispatch(getBussnissProfileAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const updateWhatsaapNumber = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(
        `${URL.updateWhatsaapNumber}`,
        data,
        config
      );
      // dispatch(getBussnissProfileAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const verifyWhatsapp = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.verifyWhatsapp}`, data, config);
      dispatch(getBussnissProfileAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getBussProfile = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.get(`${URL.getBussProfile}`, config);
      dispatch(getBussnissProfileAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getPackageList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getPackageList}/${data}`, config);
      dispatch(getpackageListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const createPurchase = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.createPurchase}`, data, config);
      // dispatch(getBussnissProfileAction(response?.data?.data));

      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getsellerTransaction = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsellerTransaction}/${data}`,
        config
      );
      dispatch(gettansactionListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getprofile = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getprofile}`, config);
      dispatch(getprofileAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getInvoice = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getInvoice}`, config);
      dispatch(getinvoiceAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

// createSellerTickets

export const createSellerTickets = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createSellerTickets}`,
        data,
        config
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getticketListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getSellerTicketList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getSellerTicketList}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getticketListAction(response?.data?.data));
      } else {
        dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getmessageSupportList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getmessageSupportList}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getmessageListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createMessage = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createMessage}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getmessageListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteacountByseller = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.deleteacountByseller}`,
        data,
        config
      );

      if (response?.data?.success) {
        localStorage.clear();
        setTimeout(navigate("/"), 1000);
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
