import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getStockDetails,
  deleteStocksTarget,
  addStockTarget,
  updateStocksTarget,
} from "../../../Redux/AllApi/action";
import { Tooltip, Modal, Input, Select, Button } from "antd";
import SvgIcon from "../../const/SvgIcon";

const { Option } = Select;

const View_Stock_Details = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [stockDetails, setStockDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddTargetModal, setOpenAddTargetModal] = useState(false); 
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [price, setPrice] = useState("");
  const [status, setStatus] = useState("");

  const fetchStockDetails = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getStockDetails(id));
      console.log("response", response);
      if (response?.success) {
        setStockDetails(response?.data);
      } else {
        console.error("Failed to fetch stock details:", response?.message);
      }
    } catch (error) {
      console.error("Error fetching stock details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchStockDetails();
    }
  }, [id]);

  const handleEditTarget = (target) => {
    setSelectedTarget(target);
    setPrice(target.price);
    setStatus(target.status);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedTarget(null);
  };

  const handleSubmit = async () => {
    const data = {
      id: selectedTarget.id,
      price,
      status,
    };

    const response = await dispatch(updateStocksTarget(data));

    if (response?.success) {
      console.log("Target updated successfully");
      handleModalClose();
      fetchStockDetails();
    } else {
      console.error("Failed to update target:", response?.message);
    }
  };

  const handleDeleteTarget = async (targetId) => {
    try {
      const response = await dispatch(deleteStocksTarget(targetId));
      if (response?.success) {
        console.log("Target deleted successfully");
        fetchStockDetails();
      } else {
        console.error("Failed to delete target:", response?.message);
      }
    } catch (error) {
      console.error("Error deleting target:", error);
    } finally {
      setOpenDeleteModal(false);
    }
  };

  const handleAddTarget = async () => {

    if (!stockDetails?.id) {
      console.error("Stock ID is missing");
      return;
    }
  
    const data = {
      price,
      status: "Pending",
      stock_id: stockDetails.id,  
    };
  
    console.log("Data being sent to API:", data); 
  
    try {
      const response = await dispatch(addStockTarget(data));  
      if (response?.success) {
        console.log('Target added successfully');
        setPrice('');  
        setOpenAddTargetModal(false);  
        fetchStockDetails();  
      } else {
        console.error('Failed to add target:', response?.message);
      }
    } catch (error) {
      console.error('Error adding target:', error);
    }
  };
  

  const handleDeleteModalClose = () => {
    setOpenDeleteModal(false);
    setSelectedTarget(null);
  };

  const containerStyle = {
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    maxWidth: "800px",
    margin: "0 auto",
  };

  const titleStyle = {
    textAlign: "center",
    color: "#333",
    fontSize: "2em",
    marginBottom: "20px",
  };

  const cardStyle = {
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    margin: "20px 0",
  };

  const cardTitleStyle = {
    textAlign: "center",
    fontSize: "1.5em",
    color: "#4CAF50",
  };

  const cardBodyStyle = {
    fontSize: "1.2em",
    color: "#555",
  };

  const cardTextStyle = {
    margin: "10px 0",
  };

  const strongTextStyle = {
    color: "#333",
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  };

  const tableHeaderStyle = {
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "10px",
    textAlign: "left",
  };

  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "left",
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Stock Details</h1>
      {loading && <p>Loading...</p>}
      {stockDetails ? (
        <div style={cardStyle}>
          <h2 style={cardTitleStyle}>{stockDetails.name}</h2>
          <div style={cardBodyStyle}>
            <p style={cardTextStyle}>
              <strong style={strongTextStyle}>Stock Type:</strong>{" "}
              {stockDetails.type}
            </p>
            <p style={cardTextStyle}>
              <strong style={strongTextStyle}>Stock Exchange:</strong>{" "}
              {stockDetails.exchange}
            </p>
            <p style={cardTextStyle}>
              <strong style={strongTextStyle}>Buy Price:</strong>{" "}
              {stockDetails.buy}
            </p>
            <p style={cardTextStyle}>
              <strong style={strongTextStyle}>Stop Loss:</strong>{" "}
              {stockDetails.stop_loss}
            </p>
          </div>
        </div>
      ) : (
        !loading && <p>No details available</p>
      )}

      {/* Add Target Button */}
      <Button
        type="primary"
        onClick={() => setOpenAddTargetModal(true)}
        style={{ marginBottom: "20px", float: "right" }}
      >
        Add Target
      </Button>

      {/* Target List */}
      {stockDetails?.target && stockDetails.target.length > 0 && (
        <div style={cardStyle}>
          <h3 style={{ textAlign: "center" }}>Target List</h3>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Target ID</th>
                <th style={tableHeaderStyle}>Price</th>
                <th style={tableHeaderStyle}>Status</th>
                <th style={tableHeaderStyle}>Created At</th>
                <th style={tableHeaderStyle}>Updated At</th>
                <th style={tableHeaderStyle}>Action</th>
                <th style={tableHeaderStyle}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {stockDetails.target.map((target) => (
                <tr key={target.id}>
                  <td style={tableCellStyle}>{target.id}</td>
                  <td style={tableCellStyle}>{target.price}</td>
                  <td style={tableCellStyle}>{target.status}</td>
                  <td style={tableCellStyle}>
                    {new Date(target.created_at).toLocaleString()}
                  </td>
                  <td style={tableCellStyle}>
                    {new Date(target.updated_at).toLocaleString()}
                  </td>
                  <td style={tableCellStyle}>
                    <Tooltip title="Edit">
                      <span
                        className="cursor-pointer"
                        onClick={() => handleEditTarget(target)}
                      >
                        <SvgIcon.Edit className="scale-icon edit-icon" />
                      </span>
                    </Tooltip>
                  </td>
                  <td style={tableCellStyle}>
                    <Tooltip title="Delete">
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          setSelectedTarget(target);
                          setOpenDeleteModal(true);
                        }}
                      >
                        <SvgIcon.deletebtn className="scale-icon edit-icon" />
                      </span>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Add Target Modal */}
      <Modal
        title="Add Target"
        visible={openAddTargetModal}
        onCancel={() => setOpenAddTargetModal(false)}
        onOk={handleAddTarget}
        okText="Add"
        cancelText="Cancel"
      >
        <div>
          <label>Stock ID:</label>
          <Input
            type="text"
            value={stockDetails?.id}
            disabled 
            style={{ marginBottom: "10px" }}
          />
        </div>
        <div>
          <label>Price:</label>
          <Input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            style={{ marginBottom: "10px" }}
          />
        </div>
      </Modal>

      {/* Modal for Editing Target */}
      <Modal
        title="Edit Target"
        visible={openModal}
        onCancel={handleModalClose}
        onOk={handleSubmit}
        okText="Save Changes"
        cancelText="Cancel"
      >
        <div>
          <label>Price:</label>
          <Input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            style={{ marginBottom: "10px" }}
          />
          <br />
          <label>Status:</label>
          <Select
            value={status}
            onChange={(value) => setStatus(value)}
            style={{ width: "100%", marginBottom: "10px" }}
          >
            <Option value="Pending">Pending</Option>
            <Option value="Complete">Completed</Option>
          </Select>
        </div>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        title="Confirm Deletion"
        visible={openDeleteModal}
        onCancel={handleDeleteModalClose}
        onOk={() => handleDeleteTarget(selectedTarget.id)}
        okText="Yes, Delete"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this target?</p>
      </Modal>
    </div>
  );
};

export default View_Stock_Details;
